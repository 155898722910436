import React, { useEffect } from 'react';
import './main.css';
import { HiOutlineLocationMarker, HiOutlineClipboardCheck } from 'react-icons/hi';
import { FaStar } from 'react-icons/fa';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const Main = ({ filteredData, cityName, maxPrice }) => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const filteredLocationsAndPrices = filteredData.filter(
    ({ location, fees }) =>
      ( // Filter by city name
        (Array.isArray(location)
          ? location.some((loc) => loc.toLowerCase() === cityName.toLowerCase())
          : location.toLowerCase() === cityName.toLowerCase())
      ) &&
      ( // Filter by max price
        parseFloat(fees) <= parseFloat(maxPrice)
      )
  );

  return (
    <section id='room' className="main container section">
      <div data-aos="fade-right" className="secTitle">
        <h3 className="title">
          {filteredLocationsAndPrices.length > 0
            ? 'Available Rooms'
            : 'No Room Available in that location (Please double-check the city name)'}
        </h3>
      </div>

      <div className="secContent grid">
        {filteredLocationsAndPrices.length > 0 ? (
          filteredLocationsAndPrices.map(
            ({ id, imgSrc, destTitle, location, ratings, fees, description }) => (
              <div key={id} data-aos="fade-up" className="sigleDestination">
                <div className="imgDiv">
                  <img src={imgSrc} alt={destTitle} />
                </div>

                <div className="cardInfo">
                  <h4 className="destTitle">{destTitle}</h4>
                  <span className="continent flex">
                    <HiOutlineLocationMarker className="icon" />
                    <span className="name">{cityName}</span>
                  </span>

                  <div className="div fees flex">
                    <div className="price">
                      <h5>£{fees}/24 hours</h5>
                    </div>
                    <div className="ratings">
                      <span>
                        <small>
                          <FaStar className="icon" /> {ratings}
                        </small>
                      </span>
                    </div>
                  </div>

                  <div className="desc">
                    <p>{description}</p>
                  </div>
                  <div className="btns flex">
                    <Link to='/book'>
                      <button className="btn flex">
                        BOOK NOW
                      </button>
                    </Link>
                    <Link to='/stay'>
                      <button className="btn flex">
                        DETAILS 
                      </button>
                    </Link>
                  </div>
                  
                </div>
              </div>
            )
          )
        ) : null}
      </div>
    </section>
  );
};

export default Main;
